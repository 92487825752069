.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-container {
  display: flex;
  position: absolute;
  top: 12%;
  bottom: 12%;
  left: 10%;
  right: 10%;
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 9998;
  text-align: center;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  outline: 0;
}
.modal-overlay,
.modal-container {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.modal-container.ReactModal__Content--after-open,
.modal-overlay.ReactModal__Overlay--after-open {
  opacity: 1;
}
.modal-container.ReactModal__Content--before-close,
.modal-overlay.ReactModal__Overlay--before-close {
  opacity: 0;
  transition-timing-function: ease-in;
}
@media only screen and (max-width: 600px) {
  /* Increase the modal size on smaller devices */
  .modal-container {
    top: 5%;
    bottom: 5%;
    left: 5%;
    right: 5%;
  }
}
/* Allow content to overflow, without affecting positioning of close button. */
.modal-content {
  flex: 1;
  overflow: auto;
}
.modal-close {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  z-index: 9999;
  border: none;
  background-color: transparent;
}
.modal-close:active {
  outline: none;
}
.modal-image {
  height: 250px;
  max-width: 100%;
  margin: 0 auto;
}
.modal-metadata-container {
  width: 80%;
  margin: 10px auto;
}
.modal-bio {
  width: 80%;
  margin: 20px auto;
  text-align: justify;
  font-size: 90%;
}
@media only screen and (max-width: 600px) {
  .modal-left {
    padding: 20px 0;
    margin: 0 auto;
    border-bottom: 1px solid #ccc;
  }
  .modal-right {
    margin: 20px 0;
  }
  .modal-bio {
    font-size: 100%; /* Larger font size on mobile */
  }
}
/*
 * Default to normal document positioning for modal (top to bottom) on smaller viewports
 * and only switch to flexbox on larger viewports.
 */
@media only screen and (min-width: 601px) {
  .modal-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  .modal-left {
    flex: 45 1 auto;
    width: 45%;
    border-right: 1px solid #ccc;
  }
  .modal-right {
    flex: 55 1 auto;
    width: 55%;
  }
  .modal-left,
  .modal-right {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
