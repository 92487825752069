.card {
  display: flex;
  flex: 0 0 160px;
  flex-flow: column nowrap;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  margin: 8px;
}
/* On smaller viewports, restrict card size and try to allow 2 cards per row. */
@media only screen and (max-width: 600px) {
  .card {
    flex: 0 0 120px;
  }
}
.card-image-region {
  width: 100%;
  overflow: hidden;
  background-size: cover;
}
.card-image {
  width: 100%;
  height: 160px;
  object-fit: cover;
  margin: auto;
  image-orientation: from-image;
}
@media only screen and (max-width: 600px) {
  .card-image {
    height: 120px;
  }
}
.card-descriptors {
  flex: 1;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
.card-name {
  margin-bottom: 0px;
  font-weight: bold;
  text-align: center;
  overflow-wrap: break-word;
}
.card-desc {
  font-size: 90%;
  text-align: center;
  padding-top: 20px;
}
.card-read-more-container {
  background-color: white;
  width: 80%;
  margin: 10px auto 15px;
}
.card-read-more {
  height: 40px;
  width: 100%;
  border: none;
  outline: 0;
  color: white;
  background-color: black;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  opacity: 0.6;
}
.card-read-more:hover {
  background-color: black;
  color: white;
  opacity: 1;
}
